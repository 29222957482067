.App {
    font-family: sans-serif;
    text-align: center;
  }
  
  .card {
    background-color: rgb(137, 137, 186) !important;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    text-transform: capitalize;
    color: white;
  }
  
  .tags {
    position: absolute;
    top: 5px;
    left: 0;
  }
  .tags > span {
    display: inline-block;
    padding: 4px;
    margin-left: 3px;
    font-size: 14px;
    background: gray;
    min-width: 40px;
  }
  

.range-indicator {
  background-color: gray;
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
}

.range-indicator .min,
.range-indicator .max {
  color: white;
  font-size: 14px;
  position: absolute;
  z-index: 1;
}

.range-indicator .min {
  left: 0;
}

.range-indicator .max {
  right: 0;
}

.range-indicator .line {
  background-color: steelblue;
  height: 4px;
  position: relative;
  width: 100%;
  z-index: 0;
  
}

.range-indicator .current {
  background-color: black;
  border-radius: 50%;
  height: 10px;
  position: absolute;
  top: -4px;
  width: 10px;
  z-index: 1;
}




.avatar {
  display: flex;
  align-items: center;
  margin-left: 50px;
}

.avatar-image-container {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #333;
  margin-right: 16px;
}

.avatar-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.avatar-placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  background-color: #555;
}

.avatar-description {
  display: flex;
  flex-direction: column;
  color: #fff;
}

.avatar-name {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 4px;
}

.avatar-title {
  font-size: 14px;
  color: #999;
}

.overlaid-button {
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: red;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlaid-button .button-number {
  font-size: 18px;
  font-weight: bold;
  color: white;
}

.overlaid-button i {
  font-size: 24px;
  color: white;
}



.card {
  background-color: white;
  border: 1px solid lightgray;
  padding: 16px;
  margin-bottom: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.card h3 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
}

.card p {
  margin: 8px 0;
  font-size: 14px;
  color: gray;
}

.line {
  height: 1px;
  width: 100%;
  background-color: lightgray;
  margin: 32px 0;
}

.learning-path {
  display: flex;
  flex-direction: column;
  align-items: center;
}




.alphabet-blocks {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
}

.alphabet-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 32px;
  /* Add this rule to display alphabet blocks in separate rows */
  float: left;
  /* Add this rule to limit the height of each alphabet block */
  height: 70px;
}

.alphabet-heading {
  margin-bottom: 16px;
}

.alphabet-heading h3 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}

.concept-badges {
  display: inline-block;
  flex-wrap: wrap;
  justify-content: center;
}

.concept-badge {
  display: inline-block;
  margin: 8px;
  padding: 8px 16px;
  background-color: lightgray;
  border-radius: 16px;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}



/* Styles for the board container */
.board-container {
  display: flex;
}

/* Styles for the columns */
.column {
  flex: 1;
  margin: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 20px;
  min-height: 500px;

}

/* Styles for the column title */
.column h2 {
  margin-top: 0;
  color: white; /* or any other appropriate color */
}

/* Styles for the task list */
.task-list {
  min-height: 100px;
}

/* Styles for the tasks */
.task {
  padding: 10px;
  margin: 5px 0;
  background-color: #f2f2f2;
  border-radius: 5px;
  cursor: grab;
}